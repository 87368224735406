import React from 'react';
import DetroitMap from './LeafletMap';
//import DetroitMap from './MapboxMap';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="leaflet-container">
        <DetroitMap />
      </div>
    </div>
  );
}

export default App;
