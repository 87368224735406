import React, { Fragment } from 'react'
import { Map as LeafletMap, TileLayer, GeoJSON, LayersControl, ScaleControl } from 'react-leaflet';
import { circleMarker } from 'leaflet';
import superagent from 'superagent';
import { map } from 'lodash';

const hash = require('object-hash');

const { BaseLayer, Overlay } = LayersControl

class Map extends React.Component {
  mapRef = React.createRef();

  constructor() {
    super();
    this.state = {
      sold2018: [],
      sold2018key: Math.random(),
      unsold2018: [],
      unsold2018key: Math.random(),
      sale2019: [],
      sale2019key: Math.random(),
      layer2018sold: false,
      layer2018unsold: false,
      layer2019sale: false,
    }
  };

  fetchGeoJson() {
    superagent.get('https://detroit.200q.io/data/unsold2018.geojson')
    .then(response => {
      console.log('Loaded unsold 2018 data!');


      // this.unsoldRef.addData(JSON.parse(response.text));
      // this.setState({
      //   unsold2018key: hash.MD5(response.text)
      // })

      // const d = new gdata(JSON.parse(response.text), {

      // });
      // d.addTo(this.mapRef);


      //this.unsoldRef.addData(JSON.parse(response.text));
      this.setState({
        unsold2018: JSON.parse(response.text),
        unsold2018key: hash.MD5(response.text),
        layer2018unsold: true,
      })
    })
    .catch(err => {
      console.log('Error fetching unsold 2018 properties');
    })

    superagent.get('https://detroit.200q.io/data/sold2018.geojson')
    .then(response => {
      console.log('Loaded sold 2018 data!');
      this.setState({
        sold2018: JSON.parse(response.text),
        sold2018key: hash.MD5(response.text),
        layer2018sold: true,
      })
    })
    .catch(err => {
      console.log('Error fetching sold 2018 properties');
    })

    superagent.get('https://detroit.200q.io/data/sale2019.geojson')
    .then(response => {
      console.log('Loaded sale 2019 data!');
      this.setState({
        sale2019: JSON.parse(response.text),
        sale2019key: hash.MD5(response.text),
        layer2019sale: true,
      })
    })
    .catch(err => {
      console.log('Error fetching sale 2019 properties');
    })
  };

  updateDimensions() {
    this.setState({
      height: window.innerHeight
    })
  };

  componentDidMount() {
    this.fetchGeoJson();
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  };

  componentDidUpdate() {
    this.mapRef.current.leafletElement.invalidateSize();
  };

  geoJSONStyleSold() {
    return {
      color: '#C80141',   // red
      weight: 1,
      fillOpacity: 0.5,
      fillColor: '#fff2af',
    }
  };

  geoJSONStyleUnsold() {
    return {
      color: '#36AB92',   // green
      weight: 1,
      fillOpacity: 0.5,
      fillColor: '#fff2af',
    }
  };

  onEachFeature(feature, layer) {
    // feature.properties is object of values
    const geoProperties = map(feature.properties, (value, key) => {
      return `<tr><td><b>${key}</b></td><td>${value}</td></tr>`;
    });

    const popupContent = `<Popup><p>Data Attributes <br /><table>${geoProperties.join(' ')}</table></Popup>`
    layer.bindPopup(popupContent)
  };

  pointToLayerUnsold(feature, latlng) {
    return circleMarker(latlng, {
      radius: 8,
      fillColor: "#ff7800",
      color: "#000",
      weight: 1,
      opacity: 1,
      fillOpacity: 0.8
    });
  };

  pointToLayerSold(feature, latlng) {
    return circleMarker(latlng, {
      radius: 8,
      fillColor: "#C80141",
      color: "#000",
      weight: 1,
      opacity: 1,
      fillOpacity: 0.8
    });
  };

  pointToLayerSale(feature, latlng) {
    return circleMarker(latlng, {
      radius: 8,
      fillColor: "#006400",
      color: "#000",
      weight: 1,
      opacity: 1,
      fillOpacity: 0.8
    });
  };

  render() {

    return (
      <Fragment>
        <LeafletMap
          center={[42.377261, -83.072041]}
          zoom={12}
          maxZoom={24}
          attributionControl={true}
          zoomControl={true}
          doubleClickZoom={true}
          scrollWheelZoom={true}
          dragging={true}
          animate={true}
          easeLinearity={0.35}
          style={{height: this.state.height}}
          ref={this.mapRef}
        >
          <LayersControl position="topright">
            <BaseLayer checked name="Basemap (Mapbox Light Gray)">
              <TileLayer
                attribution='Mapbox'
                url='https://api.mapbox.com/styles/v1/mapbox/light-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoia3lsZWNhc3RvbmRldHJvaXQiLCJhIjoiY2sxazBxbHZ0MGswdjNxcTcwZnZwd3JwdiJ9.AG6jkhnkOi-CGY4SJULpLg'
              />
              </BaseLayer>
              <BaseLayer name="Basemap (OpenStreetMap)">
                <TileLayer
                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
              </BaseLayer>
              <BaseLayer name="Basement (OpenStreetMap B&W)">
              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
              />
            </BaseLayer>
            <Overlay name="Sold 2018" checked={this.state.layer2018sold}>
              <GeoJSON
                key={this.state.sold2018key}
                data={this.state.sold2018}
                onEachFeature={this.onEachFeature}
                pointToLayer={this.pointToLayerSold}
              />
            </Overlay>
            <Overlay name="Unsold 2018" checked={this.state.layer2018unsold}>
              <GeoJSON
                key={this.state.unsold2018key}
                data={this.state.unsold2018}
                onEachFeature={this.onEachFeature}
                pointToLayer={this.pointToLayerUnsold}
              />
            </Overlay>
            <Overlay name="For Sale 2019" checked={this.state.layer2019sale}>
              <GeoJSON
                key={this.state.sale2019key}
                data={this.state.sale2019}
                onEachFeature={this.onEachFeature}
                pointToLayer={this.pointToLayerSale}
              />
            </Overlay>
          </LayersControl>
          <ScaleControl />
        </LeafletMap>
      </Fragment>
    );
  }
}

export default Map;
